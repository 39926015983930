import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ModalPop from "../components/modal/modal"

const ServicesPageBasic = () => (
	<div className="modal-hero">
		<div className="d-sm-flex justify-content-center package ">
			<div
				data-aos="fade"
				data-aos-delay="300"
				className="package_type shadow aos-init"
			>
				<h3 className="gradient-secondary px-4 px-lg-5 py-3">Basic</h3>
				<ul className="list-unstyled styled-list text-darker">
					<li>Burners and controls</li>
					<li>Refractory services</li>
					<li>Steam Trap Troubleshooting</li>
					<li>Item Emergency repairs</li>
					<li>Tube replacement</li>
					<li>Onsite Training</li>
					<li>Safety Testing</li>
					<li>MULE Program (Machinery Useful Life Examinations)</li>
					<li>Annual Cleaning and Inspections</li>
					<li>Tune ups (ask about our 'Test and Tune' program)</li>
					<li>Equipment Replacement</li>
					<li>Traps and steam service</li>
					<li>Feed Water Systems</li>
					<li>Combustion management systems</li>
					<li>Efficiency Upgrades</li>
					<li>24 hour Tech. Support</li>
				</ul>
			</div>
			<div className="text-center mb-4">
				<ModalPop />
			</div>
		</div>
	</div>
)

export default ServicesPageBasic
